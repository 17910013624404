import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Offcanvas } from 'react-bootstrap';
import { BookingPerformerModel } from '../../../models';
import { api } from '../../../store/api';
import PerformerContractDocument from './PerformerContractDocument';

const styles = StyleSheet.create({
    viewer: {
        width: window.innerWidth * 0.7,
        height: window.innerHeight - 85,
    },
});

interface LocalProps {
    show: boolean;
    bookingId: number;
    bookingPerformer: BookingPerformerModel;
    onHide: () => void;
}

const PerformerContractOffCanvas = ({ show, bookingId, bookingPerformer, onHide }: LocalProps) => {
    const { data: booking } = api.useGetBookingQuery(bookingId ?? skipToken);
    const { data: performer } = api.useGetPerformerQuery(bookingPerformer.actId);
    const { data: performerAuthorizedContact } = api.useGetContactQuery(bookingPerformer.authorizedContactId ?? skipToken);
    const { data: siteContact } = api.useGetContactQuery(booking?.siteContactId ?? skipToken);

    return (
        <Offcanvas className="w-75" show={show} onHide={onHide} placement="end">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                {performer && performerAuthorizedContact && siteContact && (
                    <PDFViewer style={styles.viewer}>
                        <PerformerContractDocument {...{ bookingPerformer, booking, performer, performerAuthorizedContact, siteContact }} />
                        {/*  booking={booking} client={client} authorizedContact={contact} performers={performers}  */}
                    </PDFViewer>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default PerformerContractOffCanvas;

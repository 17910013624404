import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { api } from '../../store/api';
import ContactListCard from '../acts/components/ContactListTable';
import Page from '../app/Page';
import AccountAddressListCard from '../shared/components/AccountAddressListCard';
import BookingSummaryTable from '../shared/components/BookingSummaryTable';
import LegacyBookingCard from './components/LegacyBookingCard';

const ClientDetailPage = () => {
    const { id = '0' } = useParams();
    const { data: client } = api.useGetClientQuery(+id ?? skipToken);
    const { data: legacyBookingIds } = api.useGetClientLegacyBookingIdsQuery(+id ?? skipToken);
    const { data: bookings, isLoading, isFetching } = api.useGetClientBookingsQuery(+id ?? skipToken);

    return (
        <Page title={client?.name ?? ''}>
            <Container fluid>
                <Tabs>
                    <Tab eventKey="client" title="Client Detail">
                        <Row>
                            <Col>{client && <ContactListCard entity={client} />}</Col>
                        </Row>
                        <Row>
                            <Col md={4}>{client && <AccountAddressListCard accountId={client.id} />}</Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="bookings" title="Bookings">
                        <Row>
                            <Col>
                                <BookingSummaryTable data={bookings} isLoading={isLoading || isFetching} />
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="approach" title="Approach Bookings">
                        <Row>
                            <Col md={6}>
                                {legacyBookingIds?.map((x) => (
                                    <div key={x}>
                                        <LegacyBookingCard legacyBookingId={x} />
                                    </div>
                                ))}
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            </Container>
        </Page>
    );
};

export default ClientDetailPage;

import { toast } from '@mobiscroll/react';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/rtk';
import { contactMaintenanceActions as actions } from '../../../store/contactMaintenanceSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { api } from '../../../store/api';

export const AccountContactMaintenancePopup = () => {
    const dispatch = useAppDispatch();
    const { workingContact, entityId, isModalOpen, accountType } = useAppSelector((state) => state.contactMaintenance);
    const [updateContact] = api.useUpdateContactMutation();
    const [addContact] = api.useAddContactMutation();

    const setFirstName = (e: React.ChangeEvent<HTMLInputElement>) => dispatch(actions.setFirstName(e.target.value));
    const setLastName = (e: React.ChangeEvent<HTMLInputElement>) => dispatch(actions.setLastName(e.target.value));
    const setTitle = (e: React.ChangeEvent<HTMLInputElement>) => dispatch(actions.setTitle(e.target.value));
    const setContactMethodName = (index: number, e: React.ChangeEvent<HTMLInputElement>) =>
        dispatch(actions.setContactMethodName({ index, value: e.target.value }));
    const setContactMethodValue = (index: number, e: React.ChangeEvent<HTMLInputElement>) =>
        dispatch(actions.setContactMethodValue({ index, value: e.target.value }));

    const save = () => {
        let message = `Added new contact: ${workingContact.firstName} ${workingContact.lastName}.`;

        if (workingContact.id === 0 && accountType) {
            addContact({ accountId: entityId, contact: workingContact, accountType });
        } else {
            message = `Saved changes to contact: ${workingContact.firstName} ${workingContact.lastName}.`;
            updateContact(workingContact);
        }

        toast({
            color: 'success',
            display: 'top',
            message,
        });
        dispatch(actions.close());
    };

    return (
        <Modal dialogClassName="w-75" show={isModalOpen}>
            <Modal.Body>
                <Form>
                    <div className="d-flex justify-content-between">
                        <Form.Group>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" value={workingContact.firstName ?? ''} onChange={setFirstName} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" value={workingContact.lastName ?? ''} onChange={setLastName} />
                        </Form.Group>
                    </div>
                    <Form.Group className="mt-2">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" value={workingContact.title ?? ''} onChange={setTitle} />
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Form.Label>Contact Methods</Form.Label>
                        {workingContact.contactMethods.map((email, index) => (
                            <Row key={index} className="d-flex justify-content-between">
                                <Col xs={2}>
                                    <Form.Label>{email.contactMethodTypeId === 1 ? 'Phone' : 'Email'}</Form.Label>
                                    <Form.Switch id={`contactMethodType-checkbox-${index}`} onChange={() => dispatch(actions.toggleContactMethodType(index))} />
                                </Col>
                                <Col xs={3}>
                                    <FloatingLabel controlId="floatingInput" label="Label" className="mb-3">
                                        <Form.Control
                                            type="text"
                                            value={email.name}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setContactMethodName(index, e)}
                                            placeholder="type/label"
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={7}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label={email.contactMethodTypeId === 1 ? 'Phone Number' : 'Email Address'}
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="text"
                                            value={email.value}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setContactMethodValue(index, e)}
                                            placeholder="email address"
                                        />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        ))}
                    </Form.Group>
                    <Button variant="success" onClick={() => dispatch(actions.addContactMethod())}>
                        <FontAwesomeIcon icon={faPlusCircle} />
                    </Button>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="light" onClick={() => dispatch(actions.close())}>
                    Cancel
                </Button>
                <Button onClick={save} color="primary">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

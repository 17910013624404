import { Datepicker } from '@mobiscroll/react';
import { Form } from 'react-bootstrap';
import { AccountModel } from '../../../models';
import ActSelect from './ActSelect';
import ClientSelect from './ClientSelect';

interface LocalProps {
    selectedAct?: AccountModel;
    selectedClient?: AccountModel;
    onSelectedActChanged: (client: AccountModel) => void;
    onSelectedClientChanged: (client: AccountModel) => void;
    selectedDate: any;
    onDateChange: (args: any, inst: any) => void;
}

const NewBookingForm = ({ selectedAct, selectedClient, onSelectedActChanged, onSelectedClientChanged, selectedDate, onDateChange }: LocalProps) => {
    const respSetting = {
        medium: {
            controls: ['calendar'], //controls: ['calendar', 'time'],
            touchUi: false,
        },
    };

    return (
        <>
            <Form.Group className="m-2">
                <Form.Label className="fw-bold">Client</Form.Label>
                <ClientSelect selectedAccountId={selectedClient?.id} onChange={onSelectedClientChanged} placeholder="select client" />
            </Form.Group>
            <Form.Group className="m-2">
                <Form.Label className="fw-bold">Act</Form.Label>
                <ActSelect selectedAccountId={selectedAct?.id} onChange={onSelectedActChanged} placeholder="select act" />
            </Form.Group>
            <div className="mbsc-form-group">
                <Datepicker
                    select="date"
                    controls={['date']}
                    touchUi={true}
                    showRangeLabels={false}
                    responsive={respSetting}
                    onChange={onDateChange}
                    value={selectedDate}
                    label="Performance Date"
                />
            </div>
        </>
    );
};

export default NewBookingForm;

import { Card } from 'react-bootstrap';
import { api } from '../../../store/api';

interface LocalProps {
    legacyBookingId: number;
}

const LegacyBookingCard = ({ legacyBookingId }: LocalProps) => {
    const { data: record } = api.useGetLegacyApproachRecordQuery(legacyBookingId);

    return (
        <Card>
            <Card.Body>
                {`${record?.serialNumber} (${legacyBookingId})`} : {record?.performer}, {record?.location}, {record?.locationAddress}
            </Card.Body>
        </Card>
    );
};

export default LegacyBookingCard;

import { Row, Col } from 'react-bootstrap';
import { PerformanceModel } from '../../../../models';
import { AccountNameLazy } from '../../../shared/components/ClientName';
import AppDate from '../../../shared/components/AppDate';
import PerformanceEditButtonSuite from './PerformanceEditButtonSuite';

interface LocalProps {
    contractId: number;
    performance: PerformanceModel;
}

const Performance = ({ contractId, performance }: LocalProps) => {
    return (
        <>
            <Row>
                <Col className="d-flex justify-content-between">
                    <h6>
                        <AppDate date={performance.date} includeDayOfWeek />
                    </h6>
                    <h5>
                        <AccountNameLazy accountId={performance.actId} />
                    </h5>
                </Col>
            </Row>
            <LabelValueRow label="Location Name" value={performance.venueName} />
            <LabelValueRow label="Address" value={performance.address?.freeformAddress} />
            <Row>
                <Col>
                    <LabelValueRow label="Check In" value={performance.checkInTime} />
                    <LabelValueRow label="Performance Times" value={performance.performanceTime} />
                </Col>
                <Col>
                    <LabelValueRow label="Performances" value={performance.numberOfPerformances} />
                    <LabelValueRow label="No. of Performers" value={performance.numberOfPerformers} />
                </Col>
                <Col md={1} className="d-flex justify-content-center">
                    <div>
                        <PerformanceEditButtonSuite contractId={contractId} performance={performance} />
                    </div>
                </Col>
            </Row>
        </>
    );
};

interface LabelValueProps {
    label: string;
    value?: string | number;
}

const LabelValueRow = ({ label, value }: LabelValueProps) => {
    return (
        <Row>
            <Col>
                <span className="text-muted">{label}: </span>
                {value}
            </Col>
        </Row>
    );
};

export default Performance;

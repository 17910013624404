import { Card, Col, Row } from 'react-bootstrap';
import { PerformanceModel, toAppDate } from '../../../models';
import Performance from './components/Performance';
import PerformanceEditButtonSuite from './components/PerformanceEditButtonSuite';

interface LocalProps {
    contractId: number;
    performances: PerformanceModel[];
}

const ContractPerformancesCard = ({ contractId, performances }: LocalProps) => {
    const defaultPerformance: PerformanceModel = performances.length
        ? performances[0]
        : {
              id: 0,
              actId: 0,
              date: toAppDate(new Date()),
              venueName: '',
              numberOfPerformers: 1,
              numberOfPerformances: 1,
          };
    const newPerformance: PerformanceModel = { ...defaultPerformance, id: 0 };
    return (
        <Card className="mixed-color-code">
            <Card.Header className="d-flex justify-content-between">
                Perfomances
                <PerformanceEditButtonSuite contractId={contractId} performance={newPerformance} />
            </Card.Header>
            <Card.Body>
                {performances.map((performance) => (
                    <Row className="border-bottom border-secondary mb-2" key={performance.id}>
                        <Col>
                            <Performance contractId={contractId} performance={performance} />
                        </Col>
                    </Row>
                ))}
            </Card.Body>
        </Card>
    );
};

export default ContractPerformancesCard;

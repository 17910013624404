import React, { useEffect, useCallback, useState } from 'react';
import { Eventcalendar, setOptions, MbscCalendarEvent, MbscEventcalendarView } from '@mobiscroll/react';
import EditEventPopup from './EditEventPopup';
import { AccountModel, CalendarEvent, BookingCreateModel, toAppDate } from '../../../models';
import ViewEventPopup from './ViewEventPopup';
import { api } from '../../../store/api';

setOptions({
    themeVariant: 'light',
});

const viewSettings: MbscEventcalendarView = {
    calendar: { labels: true, type: 'month' },
};

const ContractCalendar = () => {
    const { data: events } = api.useGetCalendarQuery();
    const [createContract] = api.useCreateContractMutation();

    useEffect(() => {
        if (events) {
            setMyEvents(events.map((x) => ({ ...x })));
        }
    }, [events]);

    const [myEvents, setMyEvents] = useState<MbscCalendarEvent[]>([]);
    const [tempEvent, setTempEvent] = useState<MbscCalendarEvent>();
    const [isCreatePopupOpen, setOpenCreatePopup] = useState<boolean>(false);
    const [isViewPopupOpen, setOpenViewPopup] = useState<boolean>(false);
    const [anchor, setAnchor] = useState<any>(null);
    const [popupEventTitle, setTitle] = useState<string | undefined>('');
    const [popupEventDate, setDate] = useState<Date>();
    const [selectedClient, setSelectedClient] = useState<AccountModel>();
    const [selectedAct, setSelectedAct] = useState<AccountModel>();
    const [mySelectedDate, setSelectedDate] = useState<any>(new Date());

    const saveEvent = useCallback(() => {
        let createContractModel: BookingCreateModel | undefined = undefined;
        if (popupEventDate && selectedAct && selectedClient) {
            createContractModel = {
                clientId: selectedClient.id,
                actId: selectedAct.id,
                performanceDate: toAppDate(popupEventDate),
                contractDate: toAppDate(new Date()),
            };
        }

        if (createContractModel) {
            createContract(createContractModel);
        }
        // add the new event to the list
        setSelectedDate(popupEventDate);
        // close the popup
        setOpenCreatePopup(false);
    }, [popupEventDate, selectedClient, selectedAct, createContract]);

    const loadPopupForm = useCallback((event: CalendarEvent) => {
        setTitle(event.title);
        setDate(event.start as Date);
        setSelectedClient(event.client);
        setSelectedAct(event.act);
    }, []);

    // handle popup form changes
    const titleChange = (ev: any) => setTitle(ev.target.value);

    interface SelectedDates {
        inst: any;
        value: Date;
        valueText: string;
    }

    const dateChange = (ev: SelectedDates, inst: any) => {
        setDate(ev.value);
    };

    // scheduler options
    const onSelectedDateChange = (event: any) => setSelectedDate(event.date);

    const onEventClick = useCallback<any>(
        (args: any) => {
            setTempEvent({ ...args.event });
            // fill popup form with event data
            loadPopupForm(args.event);
            setAnchor(args.domEvent.target);
            setOpenViewPopup(true);
        },
        [loadPopupForm]
    );

    const onEventCreated = useCallback(
        (args: any) => {
            setTempEvent(args.event);
            // fill popup form with event data
            loadPopupForm(args.event);
            setAnchor(args.target);
            // open the popup
            setOpenCreatePopup(true);
        },
        [loadPopupForm]
    );

    const onEventUpdated = useCallback((args: any) => {
        // here you can update the event in your storage as well, after drag & drop or resize
        // ...
    }, []);

    // popup options
    const popupButtons = React.useMemo<any>(() => {
        return [
            'cancel',
            {
                handler: () => {
                    saveEvent();
                },
                keyCode: 'enter',
                text: 'Add',
                cssClass: 'mbsc-popup-button-primary',
            },
        ];
    }, [saveEvent]);

    const onClose = useCallback(() => {
        // refresh the list, if add popup was canceled, to remove the temporary event
        setMyEvents([...myEvents]);
        setOpenCreatePopup(false);
    }, [myEvents]);

    const editPopupProps = {
        contractId: tempEvent?.id ? +tempEvent.id : 0,
        headerText: 'New Booking',
        anchor,
        popupButtons,
        isOpen: isCreatePopupOpen,
        onClose,
        titleChange,
        popupEventTitle,
        dateChange,
        popupEventDate,
        selectedClient,
        onSelectedClientChanged: setSelectedClient,
        selectedAct,
        onSelectedActChanged: setSelectedAct,
    };

    return (
        <>
            <Eventcalendar
                view={viewSettings}
                data={myEvents}
                clickToCreate="double"
                dragToCreate={true}
                dragToMove={true}
                dragToResize={true}
                selectedDate={mySelectedDate}
                onSelectedDateChange={onSelectedDateChange}
                onEventClick={onEventClick}
                onEventCreated={onEventCreated}
                onEventUpdated={onEventUpdated}
            />
            <EditEventPopup {...editPopupProps} />
            <ViewEventPopup anchor={anchor} isOpen={isViewPopupOpen} onClose={() => setOpenViewPopup(false)} contractId={tempEvent?.id ? +tempEvent.id : 0} />
        </>
    );
};

export default ContractCalendar;

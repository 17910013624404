import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AccountModel, Booking, toAppDate } from '../../../models';
import { api } from '../../../store/api';
import NewBookingForm from '../../shared/components/NewBookingForm';

const NewBookingButton = () => {
    const navigate = useNavigate();
    const [createContract] = api.useCreateContractMutation();
    const [selectedAct, setSelectedAct] = useState<AccountModel>();
    const [selectedClient, setSelectedClient] = useState<AccountModel>();
    const [selectedDate, setSelectedDate] = useState<Date>();
    const [isOpen, setIsOpen] = useState(false);

    const onSave = () => {
        if (selectedClient && selectedDate) {
            createContract({
                clientId: selectedClient.id,
                actId: selectedAct?.id,
                performanceDate: toAppDate(selectedDate),
                contractDate: toAppDate(new Date()),
            })
                .unwrap()
                .then((b: Booking) => navigate(`../bookings/${b.id}`, { replace: true }));

            onClose();
        }
    };

    const onClose = () => {
        setSelectedAct(undefined);
        setSelectedClient(undefined);
        setSelectedDate(undefined);

        setIsOpen(false);
    };

    return (
        <>
            <div onClick={() => setIsOpen(true)} className="item mt-3">
                <FontAwesomeIcon icon={faPlusCircle} className="sidebar-icon" />
                <span className="sidebar-text">Add Booking</span>
            </div>

            <Modal show={isOpen} onHide={onClose}>
                <Modal.Body>
                    <NewBookingForm
                        selectedAct={selectedAct}
                        selectedClient={selectedClient}
                        onSelectedClientChanged={(a) => setSelectedClient(a)}
                        onSelectedActChanged={(c) => setSelectedAct(c)}
                        selectedDate={selectedDate}
                        onDateChange={(d) => setSelectedDate(d.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onSave}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default NewBookingButton;

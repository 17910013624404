import { Container } from 'react-bootstrap';

interface LocalProps {
    title: string;
    children: React.ReactNode;
}

const Page = ({ title, children }: LocalProps) => {
    return (
        <Container fluid>
            <h1 className="app-page-title">{title}</h1>
            <div className="app-page-content">{children}</div>
        </Container>
    );
};

export default Page;

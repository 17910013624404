import { MbscPopupButton, Popup } from '@mobiscroll/react';
import { useNavigate } from 'react-router-dom';
import { Badge, Spinner } from 'react-bootstrap';
import SiteContactCard from '../../shared/components/contacts/SiteContactCard copy';
import { AccountNameLazy } from '../../shared/components/ClientName';
import clsx from 'clsx';
import ContractReceivablesInfo from '../../contracts/client/components/ContractReceivablesInfo';
import { api } from '../../../store/api';
import ContactCard from '../../shared/components/contacts/ContactCard';

const responsivePopup = {
    medium: {
        display: 'anchored',
        width: 400,
        fullScreen: false,
        touchUi: false,
    },
};

interface LocalProps {
    anchor: any;
    isOpen: boolean;
    onClose: () => void;
    contractId: number;
}

const ViewEventPopup = ({ anchor, isOpen, onClose, contractId }: LocalProps) => {
    const navigate = useNavigate();
    const { data: booking, isLoading } = api.useGetBookingQuery(contractId, { skip: !contractId });

    const openContractButton: MbscPopupButton = {
        handler: () => navigate(`../bookings/${contractId}`),
        keyCode: 'enter',
        text: 'View Booking',
        cssClass: 'mbsc-popup-button-primary',
    };

    const closeButton: MbscPopupButton = {
        handler: () => onClose(),
        keyCode: 'enter',
        text: 'Close',
        cssClass: 'mbsc-popup-button-primary',
    };

    return (
        <Popup
            display="bottom"
            fullScreen={true}
            contentPadding={false}
            anchor={anchor}
            isOpen={isOpen}
            onClose={onClose}
            responsive={responsivePopup}
            buttons={[openContractButton, closeButton]}
        >
            <div className={clsx('m-3', { 'mh-400': isLoading })}>
                {isLoading ? (
                    <div className="d-flex justify-content-center">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <>
                        <Badge bg="secondary" className="float-end">
                            {booking?.id}
                        </Badge>
                        <div className="d-flex justify-content-center">
                            <h5>{booking?.clientId && <AccountNameLazy accountId={booking.clientId} />}</h5>
                        </div>
                        <span>Performers</span>
                        <ul>
                            {booking?.performers.map((x) => (
                                <li key={x.id}>
                                    <AccountNameLazy accountId={x.actId} />
                                </li>
                            ))}
                        </ul>
                        <div className="authorized-contact-card">
                            <ContactCard title="Authorized Contract" contactId={booking?.authorizedContactId} />
                        </div>
                        <SiteContactCard contactId={booking?.siteContactId} />
                        <ContractReceivablesInfo booking={booking} />
                    </>
                )}
            </div>
        </Popup>
    );
};

export default ViewEventPopup;

import { Datepicker } from '@mobiscroll/react';
import { useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { AppDateModel, toJsDate } from '../../../models';
import { DateTime } from 'luxon';

interface LocalProps {
    value: AppDateModel;
    onChange: (date: AppDateModel) => void;
}

export const AppDatePicker = ({ value, onChange }: LocalProps) => {
    const dp = useRef<Datepicker>(null);
    const valAsJsDate = toJsDate(value);

    useEffect(() => {
        if (dp?.current) {
            dp.current.setVal(valAsJsDate);
        }
    }, [valAsJsDate]);

    const onDatePickerSelectionChange = (value: string) => {
        var date = DateTime.fromISO(value, { zone: 'UTC' });
        const model = {
            year: date.year,
            month: date.month,
            day: date.day,
            hour: 0,
            minute: 0,
            isDateOnly: true,
            isUtc: false,
        };
        onChange(model);
    };

    var dateTime = DateTime.utc(value.year, value.month, value.day);
    var val = dateTime.toISODate();

    return <Form.Control type="date" defaultValue={val} onChange={(e) => onDatePickerSelectionChange(e.target.value)} />;
};

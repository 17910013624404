import { Text, View } from '@react-pdf/renderer';
import contractStyles from '../../clientContract/contractStyleSheet';
import { PerformerContractProps } from '../PerformerContractDocument';
import { Fragment } from 'react';

const BodySection = ({ booking, bookingPerformer, performer, performerAuthorizedContact, siteContact }: PerformerContractProps) => {
    const performances = booking?.performances.filter((x) => x.actId === performer.id);

    return (
        <View style={{ ...contractStyles.body, marginHorizontal: 40 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 15 }}>
                <Text>
                    Performer: <Text style={{ ...contractStyles.h2 }}>{performer.name}</Text>
                </Text>
                <Text>Amount Due:{bookingPerformer.amountDue}</Text>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 10 }}>
                <Text>Authorized Contact:</Text>
                <View>
                    <Text>
                        {performerAuthorizedContact.firstName} {performerAuthorizedContact.lastName}
                    </Text>
                    {performerAuthorizedContact.contactMethods.map((x) => (
                        <Text key={x.id}>
                            {x.value} ({x.name})
                        </Text>
                    ))}
                </View>
            </View>

            <View style={{ flexDirection: 'row', marginTop: 10 }}>
                <Text>Performances:</Text>
                <View>
                    {performances?.map((x) => (
                        <Fragment key={x.id}>
                            <Text>
                                {x.date.month}/{x.date.day}/{x.date.year}
                            </Text>
                            <Text>{x.performanceTime}</Text>
                            <Text>{x.numberOfPerformers} performers</Text>
                            <Text>Location: {x.venueName}</Text>
                            <Text>Address: {x.address?.freeformAddress}</Text>
                        </Fragment>
                    ))}
                </View>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 10 }}>
                <Text>Site Contact:</Text>
                <View>
                    <Text>
                        {siteContact.firstName} {siteContact.lastName}
                    </Text>
                    {siteContact.contactMethods.map((x) => (
                        <Text key={x.id}>
                            {x.value} ({x.name})
                        </Text>
                    ))}
                </View>
            </View>
        </View>
    );
};

export default BodySection;

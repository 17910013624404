import { faFileContract } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import ClientContractOffCanvas from '../../documents/clientContract/ClientContractOffCanvas';

interface LocalProps {
    contractId: number;
}

const ClientContractButton = ({ contractId }: LocalProps) => {
    const [showClientContract, setShowClientContract] = useState(false);
    return (
        <>
            <Button onClick={() => setShowClientContract(true)}>
                <FontAwesomeIcon icon={faFileContract} /> Client Contract
            </Button>
            <ClientContractOffCanvas show={showClientContract} contractId={contractId} onHide={() => setShowClientContract(false)} />
        </>
    );
};

export default ClientContractButton;

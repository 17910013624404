import { Alert, Card, Col, Row } from 'react-bootstrap';
import { Booking } from '../../../models';
import { AccountNameLazy } from '../../shared/components/ClientName';
import ContactCard from '../../shared/components/contacts/ContactCard';
import SiteContactCard from '../../shared/components/contacts/SiteContactCard copy';
import AudienceInfo from './components/AudienceInfo';
import ContractClientEditButtonSuite from './components/ContractClientEditButtonSuite';
import ContractReceivablesInfo from './components/ContractReceivablesInfo';
import ClientContractButton from '../components/ClientContractButton';

interface LocalProps {
    booking: Booking;
}

const ContractClientCard = ({ booking }: LocalProps) => {
    return (
        <Card className="client-color-code">
            <Card.Header>
                <div className="d-flex justify-content-between">
                    <h5>
                        <small className="text-muted">Client </small>
                        <AccountNameLazy accountId={booking.clientId} />
                    </h5>
                    <ContractClientEditButtonSuite booking={booking} />
                </div>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xl>
                        <Row>
                            <Col>
                                <div className="authorized-contact-card">
                                    <ContactCard title="Authorized Contract" contactId={booking?.authorizedContactId} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SiteContactCard contactId={booking?.siteContactId} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <AudienceInfo booking={booking} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xl className="m-2">
                        <Row>
                            <Col>
                                {booking.paymentStatus?.isDepositPastDue && (
                                    <Alert variant="danger" className="text-center">
                                        Deposit Past Due
                                    </Alert>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ContractReceivablesInfo booking={booking} />
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col className="d-flex justify-content-end">
                                <ClientContractButton contractId={booking.id} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default ContractClientCard;

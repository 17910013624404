import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { api } from '../../../store/api';
import SaveCancelButtonRow from '../../shared/components/SaveCancelButtonRow';

interface LocalProps {
    contractId: number;
    additionalTerms: string;
}

const ContractAdditionalTerms = ({ contractId, additionalTerms }: LocalProps) => {
    const [currentValue, _setCurrentValue] = useState(additionalTerms);
    const [isEditing, setIsEditing] = useState(false);
    const [updateAdditionalTerms] = api.useUpdateAdditionalTermsMutation();

    const setCurrentValue = (e: React.ChangeEvent<HTMLInputElement>) => _setCurrentValue(e.target.value);

    const save = () => {
        updateAdditionalTerms({ contractId, additionalTerms: currentValue });
        setIsEditing(false);
    };

    const cancel = () => {
        _setCurrentValue(additionalTerms);
        setIsEditing(false);
    };

    const isPendingChanges = () => additionalTerms !== currentValue;

    return (
        <Card>
            <Card.Body>
                <Button variant="primary" hidden={isEditing} onClick={() => setIsEditing(true)} size="sm">
                    <FontAwesomeIcon icon={faEdit} size="sm" />
                </Button>
                <Form.Control className="" as="textarea" rows={2} value={currentValue} onChange={setCurrentValue} disabled={!isEditing}></Form.Control>

                {isEditing ? <SaveCancelButtonRow className="mt-2" cancel={cancel} save={save} isPendingChanges={isPendingChanges()} /> : null}
            </Card.Body>
        </Card>
    );
};

export default ContractAdditionalTerms;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { msalInstance } from '..';
import { classActRequest } from '../authConfig';
import {
    AccountModel,
    AccountType,
    AddressModel,
    AzureMapsAddressResult,
    AzureMapsAddressSerachResult,
    CalendarEvent,
    ContactModel,
    BookingCreateModel,
    Booking,
    BookingPerformerEditModel,
    BookingStatusType,
    PerformanceEditModel,
    TrackingNoteModel,
    BookingClientEditModel,
    AccountAddress,
} from '../models';
import { BookingSummary, PaymentReceipt, ReceivePaymentModel } from '../models/bookings';
import { LegacyBooking } from '../models/legacy';

export const api = createApi({
    reducerPath: 'api',
    tagTypes: [
        'Clients',
        'Performers',
        'AccountTypes',
        'AccountAddress',
        'AdobeAgreementUrls',
        'Contacts',
        'Bookings',
        'BookingStatusTypes',
        'Calendar',
        'PaymentReceipts',
        'DashboardRecords',
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL!,
        prepareHeaders: async (headers) => {
            headers.set('Accept', 'application/json');
            headers.set('X-Requested-With', 'XMLHttpRequest');
            headers.set('Content-Type', 'application/json:charset=utf-8');

            const token = await msalInstance
                .acquireTokenSilent({
                    ...classActRequest,
                    account: msalInstance.getAllAccounts()[0],
                })
                .then((response) => response.accessToken);

            headers.set('Authorization', `Bearer ${token}`);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAllAccountTypes: builder.query<AccountType[], void>({
            query: () => `api/accounttypes`,
            providesTags: ['AccountTypes'],
        }),
        clientSearch: builder.query<AccountModel[], { accountType: AccountType; search: string }>({
            query: ({ accountType, search }) => `api/clients/search/${search}`,
        }),
        performerSearch: builder.query<AccountModel[], { accountType: AccountType; search: string }>({
            query: ({ accountType, search }) => `api/performers/search/${search}`,
        }),
        getClientLegacyBookingIds: builder.query<number[], number>({
            query: (accountId) => `api/clients/${accountId}/legacy-bookings`,
        }),
        getAllPerformers: builder.query<AccountModel[], void>({
            query: () => `api/performers`,
            providesTags: ['Performers'],
        }),
        getPerformerBookings: builder.query<BookingSummary[], number>({
            query: (id) => `api/performers/${id}/bookings`,
            providesTags: (result, error, id) => ['Bookings', 'DashboardRecords'],
        }),
        getAllClients: builder.query<AccountModel[], void>({
            query: () => `api/clients`,
            providesTags: ['Clients'],
        }),
        getClient: builder.query<AccountModel, number>({
            query: (id) => `api/clients/${id}`,
            providesTags: (result, error, id) => [{ type: 'Clients', id }],
        }),
        getPerformer: builder.query<AccountModel, number>({
            query: (id) => `api/performers/${id}`,
            providesTags: (result, error, id) => [{ type: 'Performers', id }],
        }),
        getManyAccounts: builder.query<AccountModel[], number[]>({
            query: (ids) => ({
                url: 'api/performers/many',
                method: 'POST',
                body: {
                    ids,
                },
            }),
            providesTags: ['Calendar'],
        }),
        createPerformer: builder.mutation<AccountModel, string>({
            query: (actName) => ({
                url: `api/performer/${actName}`,
                method: 'POST',
            }),
            invalidatesTags: ['Performers'],
        }),
        createClient: builder.mutation<AccountModel, string>({
            query: (actName) => ({
                url: `api/clients/${actName}`,
                method: 'POST',
            }),
            invalidatesTags: ['Clients'],
        }),
        addContact: builder.mutation<AccountModel, { accountId: number; contact: ContactModel; accountType: AccountType }>({
            query: ({ accountId, contact, accountType }) => ({
                url: `api/${accountType.isAct ? 'performers' : 'clients'}/${accountId}/contacts`,
                method: 'POST',
                body: contact,
            }),
            invalidatesTags: (result, error, params) => ['Clients', 'Performers', { type: 'Contacts', id: params.contact.id }],
        }),
        updateContact: builder.mutation<void, ContactModel>({
            query: (contact) => ({
                url: `api/contacts/${contact.id}`,
                method: 'PUT',
                body: contact,
            }),
            invalidatesTags: (result, error, contact) => ['Clients', 'Performers', { type: 'Contacts', id: contact.id }],
        }),
        getClientBookings: builder.query<BookingSummary[], number>({
            query: (id) => `api/clients/${id}/bookings`,
            providesTags: (result, error, id) => ['Bookings', 'DashboardRecords'],
        }),
        addAccountAddress: builder.mutation<AccountModel, { accountId: number; addressId: number; name: string }>({
            query: (params) => ({
                url: `api/clients/${params.accountId}/address/${params.addressId}/${params.name}`, // api/clients or api/performers are the same in this case.
                method: 'POST',
            }),
            invalidatesTags: (resutl, error, input) => ['Clients', 'Performers', 'AccountAddress'],
        }),
        getAccountAddresses: builder.query<AccountAddress[], number>({
            query: (id) => `api/clients/${id}/addresses`, // api/clients or api/performers are the same in this case.
            providesTags: (result, error, id) => [{ type: 'AccountAddress', id }],
        }),
        // End Acts **************************************
        getContact: builder.query<ContactModel, number>({
            query: (id) => `api/contacts/${id}`,
            providesTags: (result, error, id) => [{ type: 'Contacts', id }],
        }),
        getCalendar: builder.query<CalendarEvent[], void>({
            query: () => 'api/calendarevents',
            providesTags: ['Calendar'],
        }),
        recordContractSignedNow: builder.mutation<number, number>({
            query: (bookingId) => ({
                url: `api/bookings/${bookingId}/record-contract-signed`, // api/clients or api/performers are the same in this case.
                method: 'PUT',
            }),
            invalidatesTags: (resutl, error, input) => ['Bookings', 'DashboardRecords'],
        }),
        // Contracts ********************************
        getAllOpenBookings: builder.query<BookingSummary[], void>({
            query: () => 'api/bookings/all-open',
            providesTags: ['DashboardRecords'],
        }),
        getAllBookingStatusTypes: builder.query<BookingStatusType[], void>({
            query: () => 'api/bookingstatustypes',
            providesTags: ['BookingStatusTypes'],
        }),
        updateContractStatusType: builder.mutation<BookingStatusType, BookingStatusType>({
            query: (model) => ({
                url: `api/bookingstatustypes`,
                method: 'PUT',
                body: model,
            }),
            invalidatesTags: ['BookingStatusTypes'],
        }),
        getBooking: builder.query<Booking, number>({
            query: (id) => `api/bookings/${id}`,
            providesTags: (result, error, id) => [{ type: 'Bookings', id }],
        }),
        createContract: builder.mutation<Booking, BookingCreateModel>({
            query: (model) => ({
                url: `api/bookings`,
                method: 'POST',
                body: model,
            }),
            invalidatesTags: (result, error, params) => ['Bookings', 'Calendar', 'DashboardRecords'],
        }),
        sendForSigning: builder.mutation<void, number>({
            query: (id) => ({
                url: `api/adobesign/${id}`,
                method: 'POST',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Bookings', id }],
        }),
        editPerformance: builder.mutation<number, { contractId: number; model: PerformanceEditModel }>({
            query: ({ contractId, model }) => ({
                url: `api/bookings/${contractId}/performance`,
                method: 'POST',
                body: model,
            }),
            invalidatesTags: (result, error, params) => [{ type: 'Bookings', id: params.contractId }, 'DashboardRecords'],
        }),
        removePerformance: builder.mutation<number, { contractId: number; performanceId: number }>({
            query: ({ contractId, performanceId }) => ({
                url: `api/bookings/${contractId}/performance/${performanceId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, params) => [{ type: 'Bookings', id: params.contractId }, 'DashboardRecords'],
        }),
        editClientInfo: builder.mutation<number, { contractId: number; model: BookingClientEditModel }>({
            query: ({ contractId, model }) => ({
                url: `api/bookings/${contractId}/client-info`,
                method: 'POST',
                body: model,
            }),
            invalidatesTags: (result, error, params) => [{ type: 'Bookings', id: params.contractId }, 'DashboardRecords'],
        }),
        addTrackingNote: builder.mutation<number, { contractId: number; note: TrackingNoteModel }>({
            query: ({ contractId, note }) => ({
                url: `api/bookings/${contractId}/tracking-note`,
                method: 'POST',
                body: note,
            }),
            invalidatesTags: (result, error, params) => [{ type: 'Bookings', id: params.contractId }],
        }),
        updateAdditionalTerms: builder.mutation<number, { contractId: number; additionalTerms: string }>({
            query: ({ contractId, additionalTerms }) => ({
                url: `api/bookings/${contractId}/additional-terms`,
                method: 'POST',
                body: {
                    additionalTerms,
                },
            }),
            invalidatesTags: (result, error, params) => [{ type: 'Bookings', id: params.contractId }],
        }),
        updatePerformer: builder.mutation<number, { contractId: number; performer: BookingPerformerEditModel }>({
            query: ({ contractId, performer }) => ({
                url: `api/bookings/${contractId}/performer`,
                method: 'POST',
                body: performer,
            }),
            invalidatesTags: (result, error, params) => [{ type: 'Bookings', id: params.contractId }, 'DashboardRecords'],
        }),
        removePerformer: builder.mutation<number, { contractId: number; performerId: number }>({
            query: ({ contractId, performerId }) => ({
                url: `api/bookings/${contractId}/performer/${performerId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, params) => [{ type: 'Bookings', id: params.contractId }],
        }),
        updateContractStatus: builder.mutation<number, { contractId: number; statusTypeId: number }>({
            query: ({ contractId, statusTypeId }) => ({
                url: `api/bookings/${contractId}/status/${statusTypeId}`,
                method: 'PUT',
            }),
            invalidatesTags: (result, error, params) => ['Calendar', { type: 'Bookings', id: params.contractId }, 'DashboardRecords'],
        }),
        getImageUrls: builder.query<string[], string>({
            query: (agreementId) => `api/adobesign/agreement/${agreementId}/imageurls`,
            providesTags: (result, error, agreementId) => [{ type: 'AdobeAgreementUrls', id: agreementId }],
        }),
        receivePayment: builder.mutation<void, ReceivePaymentModel>({
            query: (payment) => ({
                url: `api/bookings/${payment.contractId}/receive-payment`,
                method: 'POST',
                body: payment,
            }),
            invalidatesTags: (result, error, params) => [
                { type: 'Bookings', id: params.contractId },
                { type: 'PaymentReceipts', id: params.contractId },
                'DashboardRecords',
            ],
        }),
        getPaymentReceipts: builder.query<PaymentReceipt[], number>({
            query: (contractId) => `api/bookings/${contractId}/payment-receipts`,
            providesTags: (result, error, contractId) => [{ type: 'PaymentReceipts', id: contractId }],
        }),
        // Bookings ********************************
        addressSerach: builder.query<AzureMapsAddressSerachResult, string>({
            query: (searchString) => `api/maps/search/address/${searchString}`,
            providesTags: (result, error, searchString) => [{ type: 'AdobeAgreementUrls', id: searchString }],
        }),
        saveAddress: builder.mutation<AddressModel, Partial<AzureMapsAddressResult>>({
            query: (address) => ({
                url: 'api/maps',
                method: 'POST',
                body: address,
            }),
        }),

        //  Legacy Bookings - Start
        getLegacyApproachRecord: builder.query<LegacyBooking, number>({
            query: (id) => `api/legacy/approachRecords/${id}`,
        }),
        //  Legacy Bookings - End
    }),
});

import { Routes, Route, Navigate } from 'react-router-dom';
import ActsPage from '../acts/ActsPage';
import Sidebar from './SideBar';
import DashboardPage from '../dashboard/DashboardPage';
import ClientsPage from '../clients/ClientsPage';
import ActDetailPage from '../acts/ActDetailPage';
import { useAppDispatch } from '../../hooks/rtk';
import { useEffect } from 'react';
import { AccountContactMaintenancePopup, CurrentUserLoader } from '../shared';
import ClientDetailPage from '../clients/ClientDetailPage';
import ContractDetailPage from '../contracts/ContractDetailPage';
import { contractStatusTypesActions } from '../../store/types/contractStatusTypeSlice';
import { contactMethodTypesActions } from '../../store/types/contactMethodTypeSlice';
import { accountTypesActions } from '../../store/types/accountTypeSlice';
import CalendarPage from '../calendar/CalendarPage';
import MaintenanceHomePage from '../maintenance/MaintenanceHomePage';

const AppWrapper = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(contractStatusTypesActions.getAll());
        dispatch(contactMethodTypesActions.getAll());
        dispatch(accountTypesActions.getAll());
    }, [dispatch]);

    return (
        <>
            <div className="app-wrapper">
                <Sidebar />

                <div className="app-content-wrapper">
                    <Routes>
                        <Route path="/" element={<DashboardPage />} />
                        <Route path="/calendar" element={<CalendarPage />} />
                        <Route path="clients/:id" element={<ClientDetailPage />} />
                        <Route path="clients" element={<ClientsPage />} />
                        <Route path="acts/:id" element={<ActDetailPage />} />
                        <Route path="acts" element={<ActsPage />} />
                        <Route path="bookings/:id" element={<ContractDetailPage />} />
                        <Route path="maintenance" element={<MaintenanceHomePage />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </div>
            </div>
            <CurrentUserLoader />
            <AccountContactMaintenancePopup />
        </>
    );
};

export default AppWrapper;

import { store } from './store/store';
import { Provider } from 'react-redux';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { setOptions } from '@mobiscroll/react';
import AppWrapper from './areas/app/AppWrapper';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { SignInButton } from './areas/shared/components/SignInButton';

setOptions({
    //theme: 'material',
    themeVariant: 'light',
    //locale: localeDe,
});

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <AuthenticatedTemplate>
                    <AppWrapper />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <div className="app-login">
                                    <SignInButton />
                                </div>
                            }
                        />
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </UnauthenticatedTemplate>
            </BrowserRouter>
        </Provider>
    );
}

export default App;

export { AccountContactMaintenancePopup } from './components/AccountContactMaintenancePopup';
export { AppDatePicker } from './components/AppDatePicker';
export { AppDateRangePicker } from './components/AppDateRangePicker';
export { AppDateTimePicker } from './components/AppDateTimePicker';
export { AppTimePicker } from './components/AppTimePicker';
export { CurrentUserLoader } from './components/CurrentUserLoader';
export { SignInButton } from './components/SignInButton';
export { SignOutMenuItem } from './components/SignOutMenuItem';
export { PendingChangesAlertRow } from './components/PendingChangesAlertRow';

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const numberFormatter = new Intl.NumberFormat('en-US', {});

export { currencyFormatter, numberFormatter };

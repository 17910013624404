import { Popup } from '@mobiscroll/react';
import { AccountModel } from '../../../models';
import NewBookingForm from '../../shared/components/NewBookingForm';

const responsivePopup = {
    medium: {
        display: 'anchored',
        width: 400,
        fullScreen: false,
        touchUi: false,
    },
};

interface LocalProps {
    headerText: string;
    anchor: any;
    popupButtons: any;
    isOpen: boolean;
    onClose: () => void;
    popupEventTitle?: string;
    titleChange: any;
    dateChange: any;
    popupEventDate: any;
    selectedClient?: AccountModel;
    onSelectedClientChanged: (client: AccountModel) => void;
    selectedAct?: AccountModel;
    onSelectedActChanged: (client: AccountModel) => void;
}

const EditEventPopup = ({
    headerText,
    anchor,
    popupButtons,
    isOpen,
    onClose,
    dateChange,
    popupEventDate,
    selectedClient,
    onSelectedClientChanged,
    selectedAct,
    onSelectedActChanged,
}: LocalProps) => {
    return (
        <Popup
            display="bottom"
            fullScreen={true}
            contentPadding={false}
            headerText={headerText}
            anchor={anchor}
            buttons={popupButtons}
            isOpen={isOpen}
            onClose={onClose}
            responsive={responsivePopup}
        >
            <NewBookingForm
                selectedAct={selectedAct}
                selectedClient={selectedClient}
                onSelectedClientChanged={onSelectedClientChanged}
                onSelectedActChanged={onSelectedActChanged}
                selectedDate={popupEventDate}
                onDateChange={dateChange}
            />
        </Popup>
    );
};

export default EditEventPopup;

import Page from '../app/Page';
import ContractCalendar from './components/ContractCalendar';

const DashboardPage = () => {
    return (
        <Page title="Dashboard">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col">
                        <div className="contracts-calendar">
                            <ContractCalendar />
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default DashboardPage;

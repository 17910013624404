import { configureStore } from '@reduxjs/toolkit';
import currentUserState from './currentUserSlice';
import addressSearchResults from './addressSearchSlice';
import contactMaintenance from './contactMaintenanceSlice';
import contractEditClient from './contracts/editSlices/contractEditClientSlice';
import contractStatusTypes from './types/contractStatusTypeSlice';
import accountTypes from './types/accountTypeSlice';
import contactMethodTypes from './types/contactMethodTypeSlice';
import contractEditPerformer from './contracts/editSlices/contractEditPerformerSlice';
import contractEditPerformance from './contracts/editSlices/contractEditPerformanceSlice';
import { api } from './api';

export const store = configureStore({
    reducer: {
        currentUserState,
        addressSearchResults,
        contactMaintenance,
        contractStatusTypes,
        accountTypes,
        contactMethodTypes,
        contractEditClient,
        contractEditPerformer,
        contractEditPerformance,
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { Document, Page, Text, View } from '@react-pdf/renderer';
import { AccountModel, Booking, BookingPerformerModel, ContactModel } from '../../../models';
import PageHeaderView from '../clientContract/components/PageHeaderView';
import contractStyles from '../clientContract/contractStyleSheet';
import SignatureSection from '../shared/SignatureSection';
import BodySection from './components/BodySection';
import IndemnificationAgreement from './components/IndemnificationAgreement';
import StaticBottom from './components/StaticBottom';

export interface PerformerContractProps {
    booking?: Booking;
    bookingPerformer: BookingPerformerModel;
    performer: AccountModel;
    performerAuthorizedContact: ContactModel;
    siteContact: ContactModel;
}

const PerformerContractDocument = (props: PerformerContractProps) => {
    return (
        <Document>
            <Page size="A4" style={contractStyles.page}>
                <View>
                    <PageHeaderView booking={props.booking} />
                    <View style={{ flexDirection: 'column', alignItems: 'center' }}>
                        <Text style={{ ...contractStyles.h1, marginTop: 0 }}>Class Act Performer Contract</Text>
                    </View>
                    <BodySection {...props} />
                </View>
                <View>
                    <StaticBottom />
                </View>
            </Page>
            <Page size="A4" style={contractStyles.page}>
                <View>
                    <PageHeaderView booking={props.booking} />
                    <IndemnificationAgreement />
                </View>
                <View>
                    <SignatureSection />
                </View>
            </Page>
        </Document>
    );
};

export default PerformerContractDocument;
